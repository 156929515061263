import { Actor } from "skytree";
import { ElementStyle } from "@anderjason/web";
import { RayWithDistanceToBoxDemo } from "./RayWithDistanceToBoxDemo";

export class Main extends Actor<void> {
  onActivate() {
    const root = this.addActor(
      RootStyle.toManagedElement({
        tagName: "div",
        parentElement: document.body,
      })
    );
    root.setModifier("hasBackground", window == window.top);

    this.addActor(
      new RayWithDistanceToBoxDemo({
        parentElement: root.element
      })
    )
  }
}

const RootStyle = ElementStyle.givenDefinition({
  elementDescription: "Root",
  css: `
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  `,
  modifiers: {
    hasBackground: `
      background: #111;
    `
  }
});
